const supportedAPI = ["setupButton", "reattach"]; // enlist all methods supported by API (e.g. `mw('event', 'user-login');`)

const globalDebug = window.WALLNAMENT_DEBUG;

const lang = (() => {
  const parseLang = (lang) => lang?.split(/[^a-zA-Z]+/)[0];

  const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
  if (lang) {
    return parseLang(lang);
  }

  const meta = document.querySelector(
    "head meta[http-equiv='content-language']"
  );
  if (meta) {
    return parseLang(meta.getAttribute("content"));
  }
})();

function buildContainer(width, height, floating) {
  const container = document.createElement("div");
  let style = `width: ${width}px; height: ${height}px;`;
  if (floating) {
    style += "position: fixed; right: 10px; bottom: 10px;";
  }
  container.style = style;
  return container;
}

function paramName(keys) {
  let output = "";
  for (let i = 0; i < keys.length; i++) {
    const value = keys[i];
    output += i === 0 ? value : `[${value}]`;
  }

  return output;
}

function flattenInput(input, output = [], keys = []) {
  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const value = input[key];

      const currentKeysChain = keys.concat(key);

      if (Array.isArray(value)) {
        for (let arrayValue of value) {
          if (typeof arrayValue === "string") {
            output.push([`${paramName(currentKeysChain)}[]`, arrayValue]);
          } else {
            throw new Error("Array input with complex objects not supported");
          }
        }
      } else if (typeof value === "object" && value !== null) {
        flattenInput(value, output, currentKeysChain);
      } else {
        output.push([paramName(currentKeysChain), value]);
      }
    }
  }

  return output;
}

function setupButton(params) {
  let {
    selector,
    debug,
    link,
    htmlMode,
    button_config,
    buttonConfig,
    ...otherParams
  } = params;

  if (button_config) buttonConfig = button_config;

  const element = document.querySelector(selector);

  if (!element && selector) {
    (globalDebug || debug) &&
      console.error(`Wallnament element "${selector}" not found.`);

    return;
  }

  const input = {
    debug: !!(globalDebug || debug),
    link: link || document.location.href,
    ...otherParams,
  };

  if (buttonConfig) {
    input.buttonConfig = buttonConfig;
  }

  if (lang) {
    input["lang"] = lang;
  }

  const dataUrlHost = window["WALLNAMENT_HOST"] || "https://app.wallnament.com";
  const searchParams = new URLSearchParams(flattenInput(input)).toString();

  const dataUrl = `${dataUrlHost}/widget/button?${searchParams}`;

  fetch(dataUrl, {
    headers: {
      Accept: "application/json",
    },
  }).then((response) => {
    response.json().then((data) => {
      if (data.error) {
        console.error(data.error);
        return;
      }

      const { button, url, modal_url, open_url } = data;
      const width = buttonConfig?.width || 200;
      const height = buttonConfig?.height || 44;

      let elementToFill = element;

      if (htmlMode === "insert_after") {
        elementToFill = buildContainer(width, height);
        element.parentNode.insertBefore(elementToFill, element.nextSibling);
      } else if (htmlMode === "insert_before") {
        elementToFill = buildContainer(width, height);
        element.parentNode.insertBefore(elementToFill, element);
      } else if (!elementToFill) {
        elementToFill = buildContainer(width, height, true);
        document.body.insertBefore(elementToFill, null);
      }

      if (button.type === "built-in") {
        elementToFill.innerHTML = `<a href="${url}" data-open-url="${open_url}" style="display: block;width:100%;height:100%;background-image: url(${
          button.text
        }), url(${button.bg});border-radius: ${
          button.radius
        };background-position: center center, center center;background-repeat: no-repeat, no-repeat;background-size: 80% 50%, cover;border:${
          button.border ? `1px solid ${button.border}` : "none"
        };"></a>`;
        elementToFill.firstChild.addEventListener("click", (event) => {
          if (!event.metaKey) {
            event.preventDefault();
            event.stopImmediatePropagation();

            if (supportedMobileDevice()) {
              window.open(open_url);
            } else {
              openModal(modal_url);
            }
          }
        });
      }
    });
  });
}

function app(window) {
  globalDebug && console.log("Wallnament starting");

  // all methods that were called till now and stored in queue
  // needs to be called now
  let globalObject = window["Wallnament"];
  if (globalObject) {
    let queue = globalObject.q;
    if (queue) {
      for (var i = 0; i < queue.length; i++) {
        apiHandler(queue[i][0], queue[i][1]);
      }
    }
  }

  // override temporary (until the app loaded) handler
  // for widget's API calls
  window["Wallnament"] = apiHandler;
}

/**
 Method that handles all API calls
 */
function apiHandler(api, params) {
  if (!api) throw Error("API method required");

  if (!params && typeof api === "object") {
    [api, params] = ["setupButton", api];
  }

  if (supportedAPI.indexOf(api) === -1)
    throw Error(`Method ${api} is not supported`);

  switch (api) {
    case "setupButton":
      setupButton(params);
      break;
    default:
      globalDebug && console.warn(`No handler defined for ${api}`);
  }
}

function removeModal() {
  const modal = document.getElementById("wallnament-modal-wrapper");

  modal && modal.remove();
  document.body.classList.remove("wallnament-overlay");
}

document.addEventListener("keydown", (e) => {
  if (e.key === "Escape") removeModal();
});

function openModal(url) {
  removeModal();

  document.body.insertAdjacentHTML(
    "beforeend",
    `
<div id="wallnament-modal-wrapper" class="wallnament-close-trigger">
  <div id="wallnament-modal">
      <button class="wallnament-close-trigger" style="position: absolute; right: 4px; top: 4px; font-size: 24px; border: none; background: none;">×</button>
    <iframe frameBorder="0" src="${url}" style="height: 100%; width: 100%; border-radius: 8px;" onload="modalLoaded()" />
  </div>
</div>
  `
  );

  document.body.classList.add("wallnament-overlay");

  document.querySelector("#wallnament-modal iframe").onload = function () {
    document.getElementById("wallnament-modal").classList.add("loaded");
  };
  const triggers = document.getElementsByClassName("wallnament-close-trigger");

  for (let trigger of triggers) {
    trigger.addEventListener("click", function (event) {
      if (event.target !== this) return;

      removeModal();
    });
  }
}

function supportedMobileDevice() {
  return (
    /Android|iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    (navigator.userAgent.match(/Mac/) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2)
  );
}

document.head.insertAdjacentHTML(
  "beforeend",
  `<style>
body.wallnament-overlay{overflow: hidden !important;}
#wallnament-modal { max-width: 950px; max-height: 500px; width: 100%; height: 100%; background-color: white; position: relative; border-radius: 8px; }
#wallnament-modal.loaded { background: transparent; }
#wallnament-modal-wrapper { padding: 16px; background-color: rgba(0, 0, 0, 0.7); width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 2147483647; display: flex; justify-content: center; align-items: center; }
</style>`
);

app(window);
